import React from "react"

import carouselStyles from "./carousel.module.scss"

const ImageCarousel = props => {
  const images = props.images ? props.images : []
  return (
    <div id={props.id} className="carousel slide" data-ride="carousel">
      <ol className="carousel-indicators">
        {images.map((_, index) => {
          return (
            <li
              key={index}
              data-target={`#${props.id}`}
              data-slide-to={index}
              className={index === 0 ? "active" : ""}
            ></li>
          )
        })}
      </ol>
      <div className="carousel-inner">
        {images.map((image, index) => {
          return (
            <div
              key={index}
              className={`carousel-item ${carouselStyles.carouselItem} ${
                index === 0 ? "active" : ""
              }`}
            >
              <img className="d-block w-100" src={image.url} alt={image.alt} />
            </div>
          )
        })}
      </div>
      <a
        className="carousel-control-prev"
        href={`#${props.id}`}
        role="button"
        data-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="sr-only">Precedente</span>
      </a>
      <a
        className="carousel-control-next"
        href={`#${props.id}`}
        role="button"
        data-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="sr-only">Successiva</span>
      </a>
    </div>
  )
}

export default ImageCarousel
