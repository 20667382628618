import React from "react"
import { Link } from "gatsby"
import loadable from "@loadable/component"

import Layout from "../components/layout"
import Head from "../components/head"
import ImageCarousel from "../components/carousel"
const ScrollReveal = loadable(() => import("../components/scrollReveal"))

const StazionePage = () => {
  return (
    <Layout>
      <Head title="Stazione" />
      <ScrollReveal options={{ duration: 1000, origin: "top" }}>
        <ImageCarousel
          id="imageCarousel"
          images={[
            { url: "/img/image1.jpg", alt: "Prima slide!" },
            { url: "/img/image2.jpg", alt: "Seconda slide" },
            { url: "/img/image3.jpg", alt: "Terza slide" },
            { url: "/img/image4.jpg", alt: "Quarta slide" },
          ]}
        />
      </ScrollReveal>
      <section id="idea">
        <div className="container pt-3">
          <div className="row align-items-center">
            <div className="col-md-7" id="idea-text">
              <ScrollReveal options={{ duration: 2000, origin: "left" }}>
                <h1>Come nasce quest’idea?</h1>
                <div className="text-justify">
                  <p>
                    La passione della meteorologia nasce già ai tempi delle
                    superiori, dove ho avuto la fortuna di studiare questa
                    fantastica materia, per 3 anni presso l'istituto tecnico
                    nautico <em>Nazzario Sauro</em> di <em>La Spezia</em>.
                  </p>
                  <p>
                    Sia chiaro... non sono un meteorologo ma una persona con un
                    interesse, magari, non così comune per tutti.
                  </p>
                  <p>
                    Ho deciso di acquistare una stazione meteo amatoriale{" "}
                    <a
                      href="http://www.pce-italia.it/html/dati-tecnici-1/data-logger-pce-fws20.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      PCE-FWS 20
                    </a>{" "}
                    nel 2013 dopo due anni però... volevo qualcosa di più! Un
                    sito, dove trasmettere i dati della stazione, consultabile
                    da tutti e ovunque si trovino. Ecco così la creazione di{" "}
                    <Link to="/">TaladaMeteo</Link>!
                  </p>
                  <p>
                    La Stazione si trova nella Piccolissima frazione di{" "}
                    <em>Cà Ferrari</em> nel comune di <em>Ventasso</em>{" "}
                    <a
                      href="https://www.google.it/maps/place/44%C2%B023'19.0%22N+10%C2%B021'31.0%22E/@44.385,10.3472557,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x0!8m2!3d44.385!4d10.3494444?hl=it"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      latitudine 44°23'19"N - longitudine 10°21'31''E{" "}
                    </a>
                    .
                  </p>
                </div>
              </ScrollReveal>
            </div>
            <div className="col-md-5" id="idea-img">
              <ScrollReveal options={{ duration: 2000, origin: "right" }}>
                <img
                  className="img-fluid mx-auto d-block"
                  src="/img/pce-fws20.jpg"
                  style={{ width: "90%" }}
                  alt="Stazione meteo PCE-FWS 20"
                />
              </ScrollReveal>
            </div>
          </div>
        </div>
      </section>
      <section id="stazioneMeteo" className="bg-light">
        <hr />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 order-md-2" id="stazioneMeteo-text">
              <ScrollReveal
                options={{ duration: 2000, origin: "right", viewFactor: 0.2 }}
              >
                <h1>Cos'è una stazione meteo?</h1>
                <div className="text-justify">
                  <p>
                    Una stazione meteo è un insieme di sensori che permettono la
                    registrazione di dati, come: la direzione e la velocità del
                    vento, la temperatura, l'umidità relativa, la pressione
                    atmosferica e la piovosità.
                  </p>
                  <p>
                    La stazione meteo amatoriale PCE FWS20 si può comprare
                    intorno ai 100 euro su noti siti d’acquisto online e offre
                    delle misurazioni precise. Consiglio comunque di apportare
                    modifiche al pluviometro e allo schermo solare per renderlo
                    più affidabile.
                  </p>
                  <p>
                    Nel mio caso, seguendo delle guide online di{" "}
                    <a
                      href="http://www.atmosferatoscana.com/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      atmosfera toscana
                    </a>
                    , ho modificato lo schermo solare dove è contenuto lo
                    strumento di rilevazione della temperatura e dell’umidità
                    rendendolo più grande e ventilato, come potete vedere dalle
                    foto la stazione ha un “piccolo” pannello solare che
                    alimenta una ventola, in questo modo l’aria non “ristagna”
                    dentro lo schermo e la lettura della temperatura sarà più
                    precisa e veritiera.
                  </p>
                  <p>
                    Per quanto riguarda il{" "}
                    <span className="red-text">pluviometro</span> ho apportato
                    una modifica per evitare che le gocce d’acqua uscissero
                    dallo strumento in caso di pioggia e molto vento, questo
                    però ha portato a uno aumento della superficie di raccolta,
                    con conseguente{" "}
                    <span className="red-text">aumento dei valori del 10%</span>{" "}
                    (il tubo nero a sx che contiene lo strumento di misurazione
                    delle precipitazioni).
                  </p>
                </div>
              </ScrollReveal>
            </div>
            <div className="col-md-5 order-md-1" id="stazioneMeteo-img">
              <ScrollReveal
                options={{ duration: 2000, origin: "left", viewFactor: 0.2 }}
              >
                <img
                  className="rounded img-fluid mx-auto d-block"
                  src="/img/stazione-meteo.jpg"
                  alt="La mia stazione meteo"
                />
              </ScrollReveal>
            </div>
          </div>
        </div>
        <hr className="mb-0" />
      </section>
      <section id="raspberry">
        <div className="container pt-3">
          <div className="row align-items-center">
            <div className="col-md-7" id="raspberry-text">
              <ScrollReveal
                options={{ duration: 2000, origin: "left", viewFactor: 0.2 }}
              >
                <h1>Come si mette online una stazione meteo?</h1>
                <div className="text-justify">
                  <p>
                    E qui viene il bello!!! Non potendo lasciare il computer
                    acceso 24h su 24, per ovvi motivi come per esempio i
                    consumi... La rete è tornata di nuovo in mio aiuto (Le
                    persone che non si avvicinano alla passione della
                    meteorologia non hanno idea di quanti siti e forum di
                    appassionati ci sono).
                  </p>
                  <p>
                    La Risposta è stata il{" "}
                    <a
                      href="https://www.raspberrypi.org/products/raspberry-pi-1-model-b-plus/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      RaspBerry Pi B+
                    </a>{" "}
                    un mini computer economico e dai bassissimi consumi che si
                    acquista a poco più di 40$. Questo computer ha come sistema
                    operativo linux ma per il trasferimento e la lettura dei
                    dati dalla mia stazione meteo ho usato il software{" "}
                    <a
                      href="https://cumulus.hosiene.co.uk"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Cumulus Mx
                    </a>
                    .
                  </p>
                </div>
              </ScrollReveal>
            </div>
            <div className="col-md-5" id="raspberry-img">
              <ScrollReveal
                options={{ duration: 2000, origin: "right", viewFactor: 0.2 }}
              >
                <img
                  className="img-fluid mx-auto d-block"
                  src="/img/raspberry-pi.jpg"
                  alt="Raspberry Pi B+"
                />
              </ScrollReveal>
            </div>
          </div>
        </div>
      </section>
      <section id="webcam" className="bg-light">
        <hr />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 order-md-2" id="webcam-text">
              <ScrollReveal
                options={{ duration: 2000, origin: "right", viewFactor: 0.2 }}
              >
                <h1>Webcam</h1>
                <div className="text-justify">
                  <p>
                    <em>Mobotix n/d M15</em>, veramente una telecamera di ottimo
                    livello.
                  </p>
                  <p>
                    La webcam mostra una panoramica su: Talada, Casale,
                    Cervarezza,{" "}
                    <a
                      href="http://it.wikipedia.org/wiki/Monte_Ventasso"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Monte Ventasso
                    </a>
                    ,{" "}
                    <a
                      href="https://it.wikipedia.org/wiki/Monte_Nuda"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Monte Nuda
                    </a>{" "}
                    e{" "}
                    <a
                      href="https://it.wikipedia.org/wiki/Monte_Cavalbianco"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Cavalbianco
                    </a>
                    . Gli aggiornamenti sono ogni 30 minuti.
                  </p>
                  <p>
                    La telecamera si contraddistingue per le seguenti
                    caratteristiche:
                  </p>
                  <ul>
                    <li>
                      Sistema modulare con la possibilità di cambiare i sensori
                      d’immagine.
                    </li>
                    <li>
                      Telecamera a doppia ottica con protezione dall’acqua
                      (IP66) in qualsiasi tipologia di configurazione.
                    </li>
                    <li>Uso combinato giorno/notte per 24 ore Sensori 6MP.</li>
                  </ul>
                </div>
              </ScrollReveal>
            </div>
            <div className="col-md-5 order-md-1" id="webcam-img">
              <ScrollReveal
                options={{ duration: 2000, origin: "left", viewFactor: 0.2 }}
              >
                <img
                  className="img-fluid mx-auto d-block"
                  style={{ width: "70%" }}
                  src="/img/mobotix_m15.png"
                  alt="Webcam Mobotix M15"
                />
              </ScrollReveal>
            </div>
          </div>
        </div>
        <hr className="mb-0" />
      </section>
      <section id="ringraziamenti">
        <div className="container pt-5 text-center">
          <ScrollReveal
            options={{ duration: 2000, origin: "bottom", viewFactor: 0.2 }}
          >
            <div className="row">
              <div className="col-md-12">
                <p>
                  "Ringrazio Il mio amico{" "}
                  <em>ing.dott.gran.farabut.mascalzon.</em> Carlo Andreotti che
                  mi ha seguito e aiutato nello sviluppo del mio progetto."
                  <br />
                  Lucio
                </p>
              </div>
            </div>
          </ScrollReveal>
        </div>
      </section>
    </Layout>
  )
}

export default StazionePage
